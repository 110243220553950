import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Raschin
Observed Spactator
As Foucault describes in ‘The order of things’ Las Meninas is a threshold from the classical age to age of the man. Man is still representing the world, here the painter but he is looking at him self as the object of painting. The painting itself works as threshold for transformation.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/lasmenina.jpg",
        "alt": "lasmenina"
      }}></img></p>
    <p>{`Las Meninas, Diego Velázquez`}</p>
    <p>{`The role of the painter is a threshold too. He could not at the same time be seen on the picture where he is represented and also see that upon which he is representing something. He rules at that threshold of these two incompatible visibilities. Here, we need a moment of waiting, pause. A moment to look at the representer instead of represented. The painter, creator, subject should pause and look at himself as the object of the knowledge.`}</p>
    <p>{`The painter turning his eyes towards us only in so far as we happen to occupy the same position as his subject. We, the spectators, are an additional factor. We occupy the place, which the painter’s model must occupy. By positing at his model place the painter and we are in a common space. The painter gaze is toward the spectator. : “The observer and the observed take part in a ceaseless exchange. (Order of Things, 4,5) “ .`}</p>
    <p>{`There is a man who is standing at the threshold. Perhaps he too, short while ago, was there infront of the scence, in the invisible region still being contemplated by all those eyes in the picture.” (Order of Things). He is a representation of spectator, just like us on the other side. No one is looking at him. His role is the same as mirror, both of them reflects the spectator. In the former as the reflection and in the latter as representation. He is looking at peoples outside of the space of painting He is looking at:the model as being painted, as spectator (us) and the painter (real). In fact he is watching those who are watching them.`}</p>
    <p>{`In this interactive experiment, participants can see themselves in the place the spectator is standing. They share the space of painting, not only by being the observer, but also a figure inside the painting.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?time_continue=9&v=gMpyOZ0LRUQ&feature=emb_logo"
      }}>{`observed_spactator`}</a></p>
    <p>{`“The centeral paradox of the painting turns on the impossibility of representing the act of representation. The painting shows all the functions required for representation of their activity, Everything reffered to a single point where, by the internal logic of the painting and of the age, the artist, model, and spectator should all be. Velasquez can’t picture this. Something essential has not been represented.” (Order of Things)`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/LESMANINAS.jpg",
        "alt": "exhibiit"
      }}></img></p>
    <p>{`Observed Spectator in ‘CCA Far Away so Close Exhibition’ . In this version I placed the observer inside the mirror in painting.`}</p>
    <p>{`2020 by Raschin.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      